.custom-select {
  background: #fff;
  position: relative;
  display: inline-block;
  display: inherit;
  border: 1px solid #b5c0cd;
  border-radius: 8px;
  line-height: 100%;
  width: 100%;
}

.custom-select .group_selectable .custom-select--optgroup:hover {
  cursor: pointer;
}

.custom-select--optgroup {
  margin-left: 5px;
  margin-bottom: 5px;
}

.custom-select--optgroup-label {
  padding: 10px 0;
  color: #4dad33;
  font-size: 14px;
}

.custom-select--submit-btn {
  margin: 10px;
}

.custom-select.invisible-select {
  border: 0;
}

.custom-select .selected {
  color: #000;
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  padding: 8px 20px 8px 10px;
  height: 10px;
  text-overflow: ellipsis;
}

.custom-select .selected:after {
  content: "";
  display: block;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: url("../../images/base/arr-down.gif") no-repeat center center #fff;
}

.custom-select .selected.disabled {
  color: #71727d;
}

.custom-select .list_holder {
  white-space: nowrap;
  position: relative;
}

.custom-select .custom-select-spinner {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.5);
  z-index: 10;
}

.custom-select .custom-select-spinner .fa-spin {
  z-index: 15;
}

.custom-select .title {
  display: none;
  color: #000;
  font-size: 13px;
  margin-bottom: 10px;
}

.custom-select .list {
  display: none;
  position: absolute;
  top: 0;
  left: -1px;
  padding: 0;
  background: #fff;
  border: 1px solid #dddfe1;
  border-radius: 8px;
  max-width: 470px;
}

.custom-select .list.right-aligned {
  right: 0;
  left: auto;
}

.custom-select .search {
  display: none;
}

.custom-select--ul {
  overflow-x: scroll;
  max-height: 186px;
  border-radius: 8px;
}

.custom-select .noscroll {
  overflow-x: hidden !important;
}

.custom-select .no-results {
  display: none;
  padding-left: 0;
  background: none;
  cursor: default;
  height: 10px;
  font-size: 13px;
  padding: 10px 9px;
}

.custom-select .no-results:hover {
  color: #000;
  background: none;
}

.custom-select .no-results:before,
.custom-select .no-results:after {
  display: none;
}

.custom-select--li {
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 1px;
  font-size: 13px;
  padding: 10px 9px;
}

.custom-select--li.inactive {
  color: #989898;
}

.custom-select--li.true {
  color: #fff;
  background: #71737d !important;
}

.custom-select--li.true .subtitle {
  color: #fff;
}

.custom-select--li:hover,
.custom-select--li.hovered {
  color: #fff;
  background: #4dad33;
}

.custom-select--li:hover .subtitle,
.custom-select--li.hovered .subtitle {
  color: #fff;
}

.custom-select--li .subtitle {
  padding-top: 10px;
  font-style: italic;
  font-size: 11px;
  color: #b1b1b1;
}

.custom-select .list.with-search {
  padding: 10px;
  left: -12px;
  top: -58px;
  max-width: 470px;
}

.custom-select .list.with-search .title {
  display: block;
  white-space: normal;
  line-height: 18px;
}

.custom-select .list.with-search .search {
  display: block;
  margin-bottom: 5px;
}

.custom-select .list.with-search .search input {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  height: 30px;
  padding: 0 10px 0 35px;
  border: 1px solid #b5c0cd;
  transition: border-color .3s;
  background: url("../../images/base/search-22x22.png") no-repeat scroll 5px center rgba(0,0,0,0);
}

.custom-select .list.with-search .search input:focus {
  border-color: #006dd9;
}

.custom-select .list.with-search .search .no-results {
  padding-left: 2px;
  padding-bottom: 5px;
}

.custom-select .list.with-search .custom-select--submit-btn {
  margin: 10px 0 0 0;
}

.custom-select .list.checkboxed {
  max-width: 470px;
}

.custom-select .list.checkboxed .custom-select--li {
  position: relative;
  padding-left: 28px;
  margin-bottom: 1px;
}

.custom-select .list.checkboxed .custom-select--li:before {
  position: absolute;
  top: 6px;
  left: 5px;
  content: " ";
  width: 17px;
  height: 17px;
  background: url("../../images/base/custom-checkbox.gif");
  background-repeat: no-repeat;
  background-position: 0 0;
}

.custom-select .list.checkboxed .custom-select--li:hover:before {
  background-position: 0 -17px;
}

.custom-select .list.checkboxed .custom-select--li.true:before {
  background-position: 0 -34px;
}

.custom-select .list.checkboxed .custom-select--li.true:hover:before {
  background-position: 0 -34px;
}

.custom-select .list.checkboxed .custom-select .no-results {
  padding-left: 2px;
  padding-bottom: 5px;
}

.custom-select .list.checkboxed.with-line-breaks .custom-select--li {
  white-space: normal;
  width: 422px;
  line-height: 16px;
  padding-right: 20px;
}

.custom-select .list.checkboxed.with-line-breaks .custom-select--li:before {
  top: 10px;
}

.custom-select .list.simple-select {
  min-width: 200px;
}

.custom-select .list.simple-select .title {
  white-space: normal;
  word-break: break-all;
}

.custom-select .list.simple-select:not(.with-search) {
  max-width: 300px;
  padding: 10px;
  left: -12px;
  top: -58px;
}

.custom-select .list.simple-select:not(.with-search) .title {
  display: block;
}

.custom-select .list.simple-select:not(.with-search) .custom-select--ul {
  border-top: 1px solid #dddfe1;
}

