.xdsoft_datetimepicker .xdsoft_calendar td,
.xdsoft_datetimepicker .xdsoft_calendar td:hover {
  background: #fff !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current {
  box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default>div,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current>div {
  font-weight: normal !important;
  color: #fff !important;
  background: #4dad33 !important;
  border: 1px solid #4dad33 !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td>div {
  color: #000 !important;
  background: #fff !important;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 26px;
  padding: 0;
  margin: auto;
  text-align: center;
  height: 26px;
  width: 26px;
  line-height: 25px;
}

.xdsoft_datetimepicker .xdsoft_calendar td>div:hover {
  border: 1px solid #4dad33;
}

.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select.xdsoft_monthselect {
  left: 0;
}

.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select.xdsoft_yearselect {
  left: 0;
}

.xdsoft_datetimepicker .xdsoft_month {
  margin-left: 10px;
  text-align: left;
  width: 90px;
}

