.custom-checkbox .table-settings-search input[type=text],
.custom-multiple-checkbox .table-settings-search input[type=text] {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  height: 44px;
  padding: 0 10px 0 35px;
  border: 1px solid #b5c0cd;
  background: url("../../images/base/search-22x22.png") no-repeat scroll 5px center #fff;
}

.custom-checkbox.checked label,
.custom-multiple-checkbox.checked label {
  color: #000;
}

.custom-checkbox input[type=checkbox],
.custom-multiple-checkbox input[type=checkbox] {
  display: none;
}

.custom-checkbox .checkbox,
.custom-multiple-checkbox .checkbox {
  cursor: pointer;
  display: block;
  float: left;
  background: #fff;
  background: url("../../images/base/custom-checkbox.gif");
  background-repeat: no-repeat;
  background-position: 0 bottom;
  font-size: 0;
  width: 17px;
  height: 17px;
  margin-top: 1px;
  margin-right: 10px;
}

.custom-checkbox .checkbox:hover,
.custom-multiple-checkbox .checkbox:hover {
  background-position: 0 bottom;
}

.custom-checkbox .checkbox.false,
.custom-multiple-checkbox .checkbox.false {
  color: #fff;
  background-position: 0 0;
}

.custom-checkbox .checkbox.false:hover,
.custom-multiple-checkbox .checkbox.false:hover {
  background-position: 0 center;
}

.custom-checkbox label,
.custom-multiple-checkbox label {
  color: #71727d;
  font-size: 13px;
  display: inline-block !important;
  cursor: pointer;
  max-width: 92%;
}

.custom-checkbox.error label,
.custom-multiple-checkbox.error label {
  color: #8c0000;
}

.custom-checkbox.error .checkbox,
.custom-multiple-checkbox.error .checkbox {
  border: 1px solid #8c0000;
}

.custom-checkbox[disabled],
.custom-multiple-checkbox[disabled] {
  pointer-events: none;
  opacity: .5;
}

