.custom-select
  background: white
  position: relative
  display: inline-block
  display: inherit
  border: 1px solid #b5c0cd
  border-radius: 8px
  line-height: 100%
  width: 100%

  .group_selectable
    .custom-select--optgroup
      &:hover
        cursor: pointer

  &--optgroup
    margin-left:   5px
    margin-bottom: 5px

  &--optgroup-label
    padding: 10px 0
    color: #4dad33
    font-size: 14px

  &--submit-btn
    margin: 10px

  &.invisible-select
    border: 0

  .selected
    color: black
    font-size: 13px
    cursor: pointer

    overflow: hidden
    position: relative
    white-space: nowrap
    padding: 8px 20px 8px 10px
    height: 10px
    text-overflow: ellipsis

    &:after
      content: ''
      display: block
      border-radius: 8px

      position: absolute
      top:   0
      right: 0

      width:  22px
      height: 100%

      background: url("images/base/arr-down.gif") no-repeat center center white

    &.disabled
      color: #71727d

  .list_holder
    white-space: nowrap
    position: relative

  .custom-select-spinner
    position: absolute
    cursor: pointer
    width: 100%
    height: 100%
    background: rgba(255, 255, 255, 0.5)
    z-index: 10

    .fa-spin
      z-index: 15

  .title
    display: none
    color: black
    font-size: 13px
    margin-bottom: 10px

  .list
    display: none
    position: absolute
    top: 0
    left: -1px

    padding: 0
    background: white
    border: 1px solid #dddfe1
    border-radius: 8px
    max-width: 470px

    &.right-aligned
      right: 0
      left: auto

  .search
    display: none

  &--ul
    overflow-x: scroll
    max-height: 186px
    border-radius: 8px

  .noscroll
    overflow-x: hidden !important

  .no-results
    display: none
    padding-left: 0
    background: none
    cursor: default

    height: 10px
    font-size: 13px
    padding: 10px 9px


    &:hover
      color: black
      background: none

    &:before, &:after
      display: none

  &--li
    color: black
    cursor: pointer
    white-space: nowrap
    margin-bottom: 1px

    font-size: 13px
    padding: 10px 9px

    &.inactive
      color: #989898

    &.true
      color: white
      background: #71737D !important

      .subtitle
        color: white

    &:hover, &.hovered
      color: white
      background: #4dad33

      .subtitle
        color: white

    .subtitle
      padding-top: 10px
      font-style: italic
      font-size: 11px
      color: #b1b1b1

  .list.with-search
    padding: 10px
    left: -12px
    top:  -58px
    max-width: 470px

    .title
      display: block
      white-space: normal
      line-height: 18px

    .search
      display: block
      margin-bottom: 5px

      input
        width: 100%
        background: white
        box-sizing: border-box

        height: 30px
        padding: 0 10px 0 35px
        border: 1px solid #b5c0cd
        transition: border-color .3s
        background: url("images/base/search-22x22.png") no-repeat scroll 5px center transparent

        &:focus
          border-color: #006dd9
      .no-results
        padding-left: 2px
        padding-bottom: 5px

    .custom-select--submit-btn
      margin: 10px 0 0 0

  .list.checkboxed
    max-width: 470px
    .custom-select
      &--li
        position: relative
        padding-left: 28px
        margin-bottom: 1px

        &:before
          position: absolute
          top:    6px
          left:   5px

          content: ' '
          width:  17px
          height: 17px

          background: url("images/base/custom-checkbox.gif")
          background-repeat: no-repeat
          background-position: 0 0

        &:hover:before
          background-position: 0 -17px

        &.true
          &:before
            background-position: 0 -34px

          &:hover:before
            background-position: 0 -34px
      .no-results
        padding-left: 2px
        padding-bottom: 5px

    &.with-line-breaks
      .custom-select--li
        white-space: normal
        width: 422px
        line-height: 16px
        padding-right: 20px
        &:before
          top: 10px

  .list.simple-select
    min-width: 200px

    .title
      white-space: normal
      word-break: break-all

    &:not(.with-search)
      max-width: 300px
      padding: 10px
      left: -12px
      top: -58px

      .title
        display: block

      .custom-select--ul
        border-top: 1px solid #dddfe1
