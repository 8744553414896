.custom-checkbox, .custom-multiple-checkbox{
  .table-settings-search {
    input[type=text] {
      width: 100%;
      box-sizing: border-box;
      margin: 10px 0;
      height: 44px;
      padding: 0 10px 0 35px;
      border: 1px solid #b5c0cd;
      background: url("images/base/search-22x22.png") no-repeat scroll 5px center white;
    }
  }

  &.checked label {
    color: black
  }

  input[type=checkbox]{ display: none }

  .checkbox{
    cursor: pointer;
    display: block;
    float: left;
    background: white;

    background: url("images/base/custom-checkbox.gif");
    background-repeat: no-repeat;
    background-position: 0 bottom;

    font-size: 0;
    width:  17px;
    height: 17px;

    margin-top: 1px;
    margin-right: 10px;

    &:hover{ background-position: 0 bottom }

    &.false{
      color: white;
      background-position: 0 0;


      &:hover{ background-position: 0 center }
    }
  }
  label{
    color: #71727d;
    font-size: 13px;
    display: inline-block !important;
    cursor: pointer;
    max-width: 92%;
  }

  &.error {
    label {
      color: #8c0000
    }

    .checkbox {
      border: 1px solid #8c0000
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}
